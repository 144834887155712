const dataStorage = {
    env: 'UAT',
    signInText: 'Sign Into Your Account',
    baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
    theme: 'dark',
    font: '',
    listEnv: ['localhost', 'equix', 'equitystorytrader', 'morrison', 'dev1', 'ricard', 'optixtrading', 'tradeforgood'],
    whitelabel: 'equix',
    environment: 'equix',
    url: 'https://uat.equix.app',
    session: {}
}
export default dataStorage
